<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">500</h1>
            <h4 class="pt-3">Houston, we have a problem!</h4>
            <p class="text-muted">The page you are looking for is temporarily unavailable.</p>
          </div>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-search"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
            <b-input-group-append>
              <b-button variant="info">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  components: {},
    data () {
      return {
      }
    },
    async mounted () {
      debugger
      let app=this
      let code=app.$route.query.code
      if(!code) return

      app.code=code
      let res= await app.confirmEmail(code)
      if(res && res.data && res.data.ok){
        debugger
        let user_db=res.data.user
        app.show=true
      }else{
        this.token=null
        app.$store.commit('st_authentication/login_failure')
      }
    },
    methods: {
      async confirmEmail(token){
        return await this.$store.dispatch(`st_user/confirm_email`,token)
      }
    }
}
</script>
